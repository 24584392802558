import {
  AutoComplete,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  useForm,
  useSelect,
} from '@pankod/refine-antd';
import { CanAccess, useOne, useUpdate } from '@pankod/refine-core';
import { ClientType } from 'enums/client.type';
import { Role } from 'enums/role';
import { tags } from 'enums/tags';
import { ICompanies, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { convertEnumToOptions } from 'utility/enumToArray';
import { ContactType } from 'enums/contact.type';

const { TextArea } = Input;

export default function ModalEditCompany({
  open,
  onCancel,
  refetch,
  uuid,
  me,
}: {
  open: boolean;
  refetch: any;
  onCancel: any;
  uuid: number;
  me?: IUser;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { data, isLoading } = useOne({
    resource: 'companies',
    id: uuid,
  });

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  const [loading, setLoading] = useState<boolean>(false);
  const { mutate } = useUpdate();
  const save = async () => {
    setLoading(true);
    console.log('start');
    const saveData = form.getFieldsValue();
    saveData.contact = JSON.stringify(saveData.contact);
    saveData.tags = JSON.stringify(saveData.tags);
    mutate(
      {
        resource: 'companies',
        id: uuid,
        values: saveData,
        successNotification: {
          description: t('update.titleSuccess'),
          message: t('update.textSuccess'),
          type: 'success',
        },
        errorNotification: {
          description: t('error.createText'),
          message: 'Возникла ошибка',
          type: 'error',
        },
      },
      {
        onSuccess: async () => {
          setLoading(false);
          console.log('finish');
          await refetch();
          onCancel();
        },
        onError: async () => {
          setLoading(false);
        },
      },
    );
  };

  useEffect(() => {
    if (data?.data) {
      const contact = JSON.parse(data.data.contact);
      const tags = JSON.parse(data.data.tags);

      form.setFieldsValue({
        ...data?.data,
        contact,
        tags,
      });
    }
  }, [data?.data]);

  return (
    <Modal
      onOk={save}
      confirmLoading={loading}
      open={open}
      width={'100%'}
      style={{ maxWidth: '1000px' }}
      onCancel={onCancel}
      title={t('translation.change')}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          requiredMark
          layout="horizontal"
          initialValues={{
            contact: [{ name: '', phone: '' }],
            tags: ['MD'],
          }}
          size="small"
          scrollToFirstError
          labelCol={{ xs: 8 }}
        >
          <Form.Item
            label="Название компании"
            name="orgName"
            labelAlign="left"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              size="small"
              disabled={
                me?.role &&
                [
                  Role.ACCOUNTANT,
                  Role.DIRECTOR,
                  Role.ADMIN,
                  Role.MANAGER_BIG,
                  Role.CONTROL,
                  Role.SYSTEM,
                ].includes(me.role)
                  ? false
                  : true
              }
            />
          </Form.Item>
          {[ClientType.FIRM, ClientType.TRANSPORT].includes(
            data?.data.type,
          ) && (
            <>
              <Form.Item
                label="Фискальный код"
                name="codFiscal"
                hasFeedback
                labelAlign="left"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input placeholder="Фискальный код" type="number" />
              </Form.Item>

              <Form.Item
                label="TVA"
                name="tva"
                hasFeedback
                labelAlign="left"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input placeholder="TVA" type="number" />
              </Form.Item>
            </>
          )}
          <Form.Item label="Контакты" required labelAlign="left">
            <Form.List name="contact">
              {(fields, { add, remove }, item) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      rowGap: 6,
                      flexDirection: 'column',
                    }}
                  >
                    {fields.map((field) => (
                      <Card
                        size="small"
                        style={{ paddingTop: '12px' }}
                        hoverable
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item required name={[field.name, 'name']}>
                              <Input placeholder="ФИО" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item required name={[field.name, 'phone']}>
                              <Input placeholder="Телефон" />
                            </Form.Item>
                          </Col>
                          {[ClientType.FIRM, ClientType.TRANSPORT].includes(
                            data?.data.type,
                          ) && (
                            <>
                              <Col span={12}>
                                <Form.Item
                                  required
                                  name={[field.name, 'position']}
                                >
                                  <AutoComplete
                                    options={convertEnumToOptions(ContactType)}
                                    placeholder="Должность"
                                    allowClear
                                    filterOption={(inputValue, option: any) =>
                                      option.value
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !==
                                      -1
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item
                                  required
                                  name={[field.name, 'email']}
                                >
                                  <Input placeholder="Почтовый адрес" />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                          {fields.length > 1 && (
                            <Col
                              span={2}
                              style={{
                                display: 'flex',
                                alignItems: 'baseline',
                              }}
                            >
                              <Button
                                size="small"
                                danger
                                type="primary"
                                icon={<CloseOutlined />}
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          )}
                        </Row>
                      </Card>
                    ))}
                    <Button
                      type="primary"
                      style={{ marginBottom: 3 }}
                      onClick={() => add()}
                      block
                    >
                      + Добавить контакт
                    </Button>
                  </div>
                );
              }}
            </Form.List>
          </Form.Item>
          <Form.Item
            label="Реквизиты"
            name="rekvizits"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea size="small" rows={5} placeholder="Заполните реквизиты" />
          </Form.Item>
          {[ClientType.FIRM, ClientType.TRANSPORT].includes(
            data?.data.type,
          ) && (
            <Form.Item
              label="Теги"
              name="tags"
              hasFeedback
              labelAlign="left"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                size="small"
                placeholder="Выберите теги"
                style={{ width: '100%' }}
                options={tags}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Email"
            name="email"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input size="small" placeholder="Электронный адресс" />
          </Form.Item>
          <Form.Item
            label="Комментарий"
            name="comment"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea size="small" rows={3} placeholder="Опишите компанию" />
          </Form.Item>
          {[ClientType.SENDER].includes(data?.data.type) && (
            <Form.Item
              label="Адрес"
              name="adresse"
              hasFeedback
              labelAlign="left"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TextArea rows={3} size="small" placeholder="Введите адрес" />
            </Form.Item>
          )}
          {[ClientType.FIRM, ClientType.TRANSPORT].includes(
            data?.data.type,
          ) && (
            <Form.Item
              label="Web сайт"
              name="web"
              hasFeedback
              labelAlign="left"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input size="small" placeholder="Веб-сайт компании" />
            </Form.Item>
          )}
          <Form.Item
            label="Регион"
            name="region"
            hasFeedback
            labelAlign="left"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input size="small" placeholder="Введите регион" />
          </Form.Item>
          {data?.data.type === ClientType.FIRM && (
            <Form.Item
              label="Страна экспорта"
              name="exportCountry"
              hasFeedback
              labelAlign="left"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input size="small" placeholder="Заполните страну экспорта" />
            </Form.Item>
          )}
          <Form.Item
            name="type"
            hasFeedback
            hidden
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
